import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faGithub, 
    faLinkedin, 
    faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import {
    faGlasses,
    faLaptopCode,
    faSchool,
    faLocationDot,
    faHeart,
    faEnvelopesBulk,
    faEnvelope,
    faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import {
    faLaravel,
    faBootstrap,
    faJsSquare,
    faHtml5,
    faReact,
} from '@fortawesome/free-brands-svg-icons';
import List from "antd/lib/list";

import Container from "../../core/Container";
import Anchor from "../../core/Anchor";
import Text from "../../core/Typography/Text";

export const AboutListGroup = () => {
    const listGroupItems = {
        name: {
            icon: faGlasses,
            title: "Nicole Anne Reyes (Nikki)",
            description: "",
        },
        professional_exp: {
            icon: faBriefcase,
            title: "Jr. Web Developer at Spiralytics, Inc.",
            description: "",
        },
        academic_exp: {
            icon: faSchool,
            title: "Alumna at Informatics College Manila",
            description: "",
        },
        location: {
            icon: faLocationDot,
            title: "Manila, Philippines",
            description: "",
        },
        likes: {
            icon: faHeart,
            title: "Time spent mostly on bingewatching, reading, drinking iced coffee, and coding",
            description: "",
        },
        projects: {
            icon: faLaptopCode,
            title: "Hosted projects using these web technologies:",
            description: [faLaravel, faBootstrap, faJsSquare, faHtml5, faReact],
        },
        contact: {
            icon: faEnvelopesBulk,
            title: "Reach out via:",
            description: {
                github: {
                    href: "https://github.com/nikkipanda-dev",
                    icon: faGithub,
                    image: "icons/github.png",
                },
                linkedin: {
                    href: "https://www.linkedin.com/in/reyesnicoleanne",
                    icon: faLinkedin,
                    image: "icons/github.png",
                },
                facebook: {
                    href: "https://www.facebook.com/nicoleannereyes",
                    icon: faFacebook,
                    image: "icons/github.png",
                },
                email: {
                    href: "mailto:codebynikki@gmail.com",
                    icon: faEnvelope,
                    image: "icons/github.png",
                },
            },
        },
    }

    return (
        <List itemLayout="horizontal">
        {
            (Object.keys(listGroupItems).length > 0) && 
            Object.keys(listGroupItems).map((i, val) => 
                <List.Item key={i}>
                    <List.Item.Meta
                    title={
                        <Text type="span">{Object.values(listGroupItems)[val].title}</Text>
                    }
                    avatar={
                        <Container className="text-center" css={{ width: '30px', }}>
                            <FontAwesomeIcon
                                className="fa-xl ms-auto"
                                icon={Object.values(listGroupItems)[val].icon}
                                style={{ color: '#8700F8', mixBlendMode: 'multiply', }} />
                        </Container>
                    }
                    {
                        ...Object.keys(listGroupItems[i].description) && 
                        (Object.keys(listGroupItems[i].description).length > 0) && {
                            description: 
                            <Container className="d-flex flex-wrap justify-content-start align-items-center py-2">
                                {
                                    (i === "projects") ? 
                                    (Object.values(listGroupItems[i].description).map((i, val) =>
                                        <FontAwesomeIcon
                                        key={val}
                                        className="fa-3x me-3"
                                        icon={i}
                                        style={{ color: '#000', }} />
                                    )) :
                                    (i === "contact") && 
                                    Object.values(listGroupItems[i].description).map((i, val) =>
                                        <Anchor
                                        key={val}
                                        className="me-3"
                                        text={
                                            <FontAwesomeIcon icon={i.icon} className="fa-fw fa-3x" />
                                        }
                                        target="_blank"
                                        href={i.href} />
                                    )
                                }
                            </Container>
                        }
                    } />
                </List.Item>
            )
        }
        </List>
    )
}

export default AboutListGroup;