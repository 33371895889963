import { styled, } from "../../../stitches.config";

const Wrapper = styled('div', {});

export const Container = ({ 
    children,
    className,
    css,
    dataAos,
    dataAosDelay,
    dataAosDuration,
    dataAosEasing,
    dataAosMirror,
    dataAosOnce,
    dataAosAnchorPlacement,
 }) => {
    return (
        <Wrapper 
        {...className && {className: className}} 
        {...css && {css: {...css}}}
        {...dataAos && {"data-aos": dataAos}}
        {...dataAosDelay && { "data-aos-delay": dataAosDelay }}
        {...dataAosDuration && { "data-aos-duration": dataAosDuration }}
        {...dataAosEasing && { "data-aos-easing": dataAosEasing }}
        {...dataAosMirror && { "data-aos-mirror": dataAosMirror }}
        {...dataAosOnce && { "data-aos-once": dataAosOnce }}
        {...dataAosAnchorPlacement && { "data-aos-anchor-placement": dataAosAnchorPlacement }}>
            {children}
        </Wrapper>
    )
}

export default Container;