import { useState, useLayoutEffect, } from 'react';
import AOS from 'aos';
import { styled } from '../../stitches.config';

import Section from '../../components/core/Section';
import Row from '../../components/core/Row';
import Column from '../../components/core/Column';
import Heading from "../../components/core/Typography/Heading";
import Text from '../../components/core/Typography/Text';
import Container from '../../components/core/Container';

AOS.init();

export const LandingPage = ({ width, }) => {
    const [headingType, setHeadingType] = useState(
        (window.innerWidth >= 992) ? 1 :
        (window.innerWidth >= 575) ? 2 :
        4
    );

    const handleHeadingType = headingType => setHeadingType(headingType);

    const landingPageStyle = {
        padding: (width > 575) ? '$2' : "50% $2",
        '> div': {
            maxWidth: '600px',
            'p': {
                marginTop: '$3',
                maxWidth: '400px',
            }
        },
    }

    useLayoutEffect(() => {
        const updateHeadingSize = () => {
            handleHeadingType(
                (window.innerWidth >= 992) ? 1 :
                (window.innerWidth >= 575) ? 2 :
                4
            );
        }

        window.addEventListener('resize', updateHeadingSize);

        return () => {
            window.removeEventListener('resize', updateHeadingSize);
        };
    }, []);

    return (
        headingType && 
        <Section 
        css={{ 
            minHeight: (width > 575) ? '90vh' : 'auto',
        }}>
            <Container 
            css={{ minHeight: 'inherit', }}
            dataAos="fade-up"
            dataAosDelay={200}
            dataAosDuration={200}
            dataAosEasing="ease-in-out"
            dataAosMirror={true}
            dataAosOnce={true}
            dataAosAnchorPlacement="top-center">
                <Row 
                className="mx-0" 
                css={{ minHeight: 'inherit', }}>
                    <Column 
                    className="col-12 d-flex flex-column justify-content-center align-items-center" 
                    css={{ ...landingPageStyle, }}>
                        <Container>
                            <Heading
                            text={((width > 370) ? '👋' : '') + ' Hi! I am Nikki.'}
                            type={headingType}
                            className="text-center mx-auto text-break" />
                            <Text type="paragraph" className="mx-auto">
                                Welcome to my portfolio. I am excited to share the web technologies that I find interesting, and projects that I have been working on.
                            </Text>
                        </Container>
                    </Column>
                </Row>
            </Container>
        </Section>
    )
}

export default LandingPage;