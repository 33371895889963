
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode, } from '@fortawesome/free-solid-svg-icons';
import { Timeline } from 'antd';

import Container from "../../core/Container";
import Heading from "../../core/Typography/Heading";
import Image from "../../core/Image";
import Text from '../../core/Typography/Text';

const mobileViewStyle = {
    'ul li div.ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head': {
        background: 'transparent',
    },
    'ul li div.ant-timeline-item-head': {
        lineHeight: '10px',
    },
    '.ant-timeline-item-content:nth-child(n+2) > div': {
        display: 'flex',
        flexDirection: 'column',
    }
}

const DesktopView = () => {
    return (
        <>
            <Heading text="Journey" type={4} />
            <Image
                className="d-none d-sm-block align-self-center"
                src="timeline_journey_2023.png"
                alt="An image showing my journey."
                css={{
                    width: '100%',
                    maxWidth: '550px',
                    height: 'auto',
                }} />
        </>
    )
}

const MobileView = () => {
    const items = [
        {
            id: 0,
            years: "2019-2023",
            title: "Bachelor of Science in Information Technology,",
            description: "Informatics College Manila",
        },
        {
            id: 1,
            years: "2019-2023",
            title: "Bachelor of Science in Information Technology,",
            description: "Informatics College Manila",
        },
        {
            id: 2,
            years: "2017-2019",
            title: "Social Application Support II,",
            description: "adish Int'l Corp.",
        },
        {
            id: 3,
            years: "2010-2014",
            title: "Bachelor of Mass Communication,",
            description: "Pamantasan ng Lungsod ng Maynila",
        },
    ]

    return (
        <Container
        className="d-block d-sm-none mt-3"
        css={{ ...mobileViewStyle, }}>
            <Heading text="Journey" type={4} />
            <Container css={{ padding: '$2', }}>
                <Timeline>
                    <Timeline.Item
                    color="#00DAE8"
                    dot={
                        <FontAwesomeIcon
                        className="fa-xl"
                        icon={faLaptopCode}
                        style={{ 
                            color: '#8700F8', 
                            mixBlendMode: 'multiply',
                        }} />
                    }>
                        <Text type="span">...and still exploring new tech!</Text>
                    </Timeline.Item>
                    {
                        (Object.keys(items).length > 0) &&
                        Object.keys(items).map((_, val) =>
                            <Timeline.Item key={val} color="#FF771E">
                                <Container>
                                    <Text type="span">{Object.values(items)[val].years}</Text>
                                    <Text type="span">{Object.values(items)[val].title}</Text>
                                    <Text type="span" color="secondary">{Object.values(items)[val].description}</Text>
                                </Container>
                            </Timeline.Item>
                        )
                    }
                </Timeline>
            </Container>
        </Container>
    )
}

const components = {
    desktop: DesktopView,
    mobile: MobileView,
}

export const AboutJourney = ({ width, }) => {
    const Journey = (width > 575) ? components["desktop"] : components["mobile"];

    return <Journey />
}

export default AboutJourney;