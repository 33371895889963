import React from "react";
import AOS from 'aos';

import Section from '../../components/core/Section';
import Row from '../../components/core/Row';
import Column from '../../components/core/Column';
import { About as Bio } from "../../components/widgets/About";
import AboutJourney from "../../components/widgets/AboutJourney";
import Container from "../../components/core/Container";

AOS.init();

export const About = React.forwardRef(( { width }, ref ) => {
    return (
        <Section 
        css={{ minHeight: '90vh', }} 
        ref={ ref }>
            <Container css={{ 
                paddingTop: '100px',
            }}
            dataAos="fade-up"
            dataAosDelay={200}
            dataAosDuration={300}
            dataAosEasing="ease-in-out"
            dataAosMirror={true}
            dataAosOnce={true}
            dataAosAnchorPlacement="top-center">
                <Row className="mx-0">
                    <Column 
                    className="col-12"
                    css={{
                        padding: '$2',
                    }}>
                        <Bio />
                    </Column>
                </Row>
                <Row className="mx-0" css={{ marginTop: '70px', }}>
                    <Column className="p-2 d-flex flex-column">
                        <AboutJourney width={width}/>
                    </Column>
                </Row>
            </Container>
        </Section>
    )
});

export default About;