import { useState, } from 'react';
import { 
    Form, 
    Input,
    Alert,
} from 'antd';
import { func, } from '../../../util';

import Container from '../../core/Container';
import Text from '../../core/Typography/Text';
import Button from '../../core/Button';

export const ContactForm = ({ layout, onStore, }) => {
    const [form] = Form.useForm();

    const [showStatusFirstName, setShowStatusFirstName] = useState(false);
    const [validateStatusFirstName, setValidateStatusFirstName] = useState('');
    const [helpFirstName, setHelpFirstName] = useState('');
    const [showStatusLastName, setShowStatusLastName] = useState(false);
    const [validateStatusLastName, setValidateStatusLastName] = useState('');
    const [helpLastName, setHelpLastName] = useState('');
    const [showStatusEmail, setShowStatusEmail] = useState(false);
    const [validateStatusEmail, setValidateStatusEmail] = useState('');
    const [helpEmail, setHelpEmail] = useState('');
    const [showStatusMessage, setShowStatusMessage] = useState(false);
    const [validateStatusMessage, setValidateStatusMessage] = useState('');
    const [helpMessage, setHelpMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const clearHelp = () => {
        setValidateStatusFirstName('');
        setValidateStatusLastName('');
        setValidateStatusEmail('');
        setValidateStatusMessage('');
        setHelpFirstName('');
        setHelpLastName('');
        setHelpEmail('');
        setHelpMessage('');
        setShowStatusFirstName(false);
        setShowStatusLastName(false);
        setShowStatusEmail(false);
        setShowStatusMessage(false);
    }

    const onFinish = values => {
        let contactForm = {};

        contactForm = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "email": values.email,
            "body": values.body
        }

        clearHelp();

        onStore(contactForm)
        .then(() => {
            form.resetFields();
            setIsSubmitted(true);
        })

        .catch(err => {
            setShowStatusFirstName(true);
            setShowStatusLastName(true);
            setShowStatusEmail(true);
            setShowStatusMessage(true);

            if (err && err.errors) {
                func({
                    field_name: err.errors[0].extensions.field,
                    message: (err.errors[0].extensions.type === 'required') ? 
                    ((err.errors[0].extensions.field.charAt(0).toUpperCase() + err.errors[0].extensions.field.slice(1).toLowerCase()).replaceAll('_', ' ') + (' is required!!!')) : '',
                    fields: {
                        first_name: {
                            setHelp: setHelpFirstName,
                            setValidateStatus: setValidateStatusFirstName,
                        },
                        last_name: {
                            setHelp: setHelpLastName,
                            setValidateStatus: setValidateStatusLastName,
                        },
                        email: {
                            setHelp: setHelpEmail,
                            setValidateStatus: setValidateStatusEmail,
                        },
                        body: {
                            setHelp: setHelpMessage,
                            setValidateStatus: setValidateStatusMessage,
                        },
                    },
                });
            }
        });
    };

    return (
        <Container>
        {
            (isSubmitted === true) && 
            <Alert 
            className="my-4"
            message="Message submitted. Thank you." 
            type="success" 
            showIcon />
        }
            <Form
            {...layout}
            form={form}
            name="contact-form"
            onFinish={onFinish}
            preserve={false}
            size="large"
            style={{ width: '100%', }}>
                <Form.Item
                name="first_name"
                label={<Text type="span">First name</Text>}
                hasFeedback={showStatusFirstName}
                {...validateStatusFirstName && { validateStatus: validateStatusFirstName }}
                {...helpFirstName && { help: helpFirstName }}
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: 'First name is required.',
                    },
                    {
                        min: 2,
                        max: 100,
                        message: 'First name must be at least 2 and cannot be longer than 100 characters.',
                    }
                ]}>
                    <Input allowClear style={{ borderRadius: '10px', }} />
                </Form.Item>
                <Form.Item
                name="last_name"
                label={<Text type="span">Last name</Text>}
                hasFeedback={showStatusLastName}
                {...validateStatusLastName && { validateStatus: validateStatusLastName }}
                {...helpLastName && { help: helpLastName }}
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: 'Last name is required.',
                    },
                    {
                        min: 2,
                        max: 100,
                        message: 'Last name must be at least 2 and cannot be longer than 100 characters.',
                    },
                ]}>
                    <Input allowClear style={{ borderRadius: '10px', }} />
                </Form.Item>
                <Form.Item
                name="email"
                label={<Text type="span">Email address</Text>}
                hasFeedback={showStatusEmail}
                {...validateStatusEmail && { validateStatus: validateStatusEmail }}
                {...helpEmail && { help: helpEmail }}
                rules={[
                    {
                        required: true,
                        type: 'regexp',
                        message: 'Email address is required.',
                    },
                    {
                        pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                        message: "Email address is invalid.",
                    }
                ]}>
                    <Input allowClear style={{ borderRadius: '10px', }} />
                </Form.Item>
                <Form.Item
                name="body"
                label={<Text type="span">Message</Text>}
                hasFeedback={showStatusMessage}
                {...validateStatusMessage && { validateStatus: validateStatusMessage }}
                {...helpMessage && { help: helpMessage }}
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: 'Message is required.',
                    },
                    {
                        min: 10,
                        max: 5000,
                        message: 'Message must be at least 10 and cannot be longer than 5000 characters.',
                    },
                ]}>
                    <Input.TextArea
                    allowClear
                    showCount
                    maxLength={5000}
                    rows={5}
                    style={{ borderRadius: '10px', }} />
                </Form.Item>
                <Container className="d-flex">
                    <Button
                    className="mx-auto flex-grow-1 flex-sm-grow-0 mt-4"
                    text="Submit"
                    size="medium"
                    type=" submit" />
                </Container>
            </Form>
        </Container>
    )
}

export default ContactForm;