import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, } from '@fortawesome/free-solid-svg-icons';

import Card from '../Card';
import Container from '../../core/Container';
import Heading from '../../core/Typography/Heading';
import Text from '../../core/Typography/Text';
import Image from '../../core/Image';
import ProjectTech from '../ProjectTech';
import ProjectFooter from '../ProjectFooter';

import { storyblokEditable } from "@storyblok/react";

const descriptionWrapperStyle = {
    background: '#fff',
    borderRadius: '$small',
}

export const Project = ({ blok }) => {      
    return (
        <Card
        size="full"
        {
            ...blok && blok.is_completed && blok.link_type && {
                footer: <ProjectFooter type={blok.link_type} />
            }
        }
        css={{
            maxWidth: '800px',
        }}>
            <Container className="text-center">
                <Image src={blok.image_url ?? blok.image.filename} type="project" />
            </Container>
            <Container css={{ margin: '$4 0px' }}>
                <Heading type={5} text={blok.title} />
            </Container>
            <Container
            className="py-2 px-4 mt-4"
            css={descriptionWrapperStyle}>
                <Text type="paragraph" css={{ marginBottom: '$5', }}>
                    {blok.description}
                </Text>
                <hr />
                <Container>
                    <Text type="span" as="b" css={{ marginRight: '$2', }}>Status:</Text>
                    {
                        blok.is_completed ? 
                        <Text type="span">🎉 Completed</Text> :
                        <>
                            <FontAwesomeIcon icon={faBarsProgress} className="fa-fw fa-md" />
                            <Text type="span" css={{ marginLeft: '$1', }}>Ongoing</Text>
                        </>
                    }
                </Container>
                {
                    blok.tech.length && 
                    <ProjectTech values={ blok.tech } />
                }
            </Container>
        </Card>
    )
}

export default Project;