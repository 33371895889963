import React from 'react';
import AOS from 'aos';
import { directus, } from '../../util/Requests';

import Section from '../../components/core/Section';
import Row from '../../components/core/Row';
import Column from '../../components/core/Column';
import Heading from "../../components/core/Typography/Heading";
import Text from '../../components/core/Typography/Text';
import Card from '../../components/widgets/Card';
import Container from '../../components/core/Container';
import ContactForm from '../../components/widgets/ContactForm';

const layout = {
    labelCol: {
        sm: { span: 5, },
        md: { span: 4, },
    },
    wrapperCol: { span: 20, },
};

AOS.init();

export const Contact = React.forwardRef(( { width }, ref ) => {
    return (
        <Section 
        css={{ minHeight: '90vh', marginBottom: '150px', }} 
        ref={ ref }>
            <Container
            dataAos="fade-up"
            dataAosDelay={1000}
            dataAosDuration={300}
            dataAosEasing="ease-in-out"
            dataAosMirror={true}
            dataAosOnce={true}
            dataAosAnchorPlacement="top-center"
            css={{
                paddingTop: '100px',
            }}>
                <Row className="mx-0">
                    <Column 
                    className="col-12" 
                    css={{
                        padding: '$2',
                    }}>
                        <Container css={{ 
                            '.ant-row:nth-child(n+2)': {
                                marginTop: '$3',
                            },
                        }}>
                            <Heading text="Drop me a line" type={2} />
                            <Text type="paragraph">
                                Let me know if you are interested to collaborate, eager to discuss career opportunities, or anything in between.
                                Not too keen to use the form? You may email me at <Text type="span" as="b">codebynikki@gmail.com</Text>.
                            </Text>
                            <Card background="transparent">
                                <ContactForm layout={layout} onStore={createContact} />
                            </Card>
                        </Container>
                    </Column>
                </Row>
            </Container>
        </Section>
    )
});

function createContact(form) {
    const contacts = directus.items('contacts');

    return contacts.createOne(form);
}

export default Contact;