import { styled } from "../../../stitches.config";

import Heading from "../../core/Typography/Heading";

const CardWrapper = styled('div', {
    borderRadius: '$default',
    background: "$plasterWhite",
    variants: {
        size: {
            full: {
                width: '100%',
            },
            medium: {
                width: '420px',
            }
        },
        background: {
            transparent: {
                background: 'transparent',
            }
        }
    }
});
const CardHeaderWrapper = styled('div', {});
const CardBodyWrapper = styled('div', {});
const CardFooterWrapper = styled('div', {});

export const Card = ({ 
    children, 
    size,
    className, 
    background,
    css, 
    title, 
    footer,
}) => {
    return (
        <CardWrapper 
        className={ 'p-3' + (className ? (' ' + className) : '') }
        { ...background && { background: background }}
        size={ size } 
        { ...css && { css: { ...css } }}>
        {
            title && 
            <CardHeaderWrapper>
                <Heading text={title} type={ 5 } />
            </CardHeaderWrapper>
        }
            <CardBodyWrapper>
                {children}
            </CardBodyWrapper>
            <CardFooterWrapper className="d-flex flex-wrap justify-content-center align-items-center mt-4 p-2">
                { footer }
            </CardFooterWrapper>
        </CardWrapper>
    )
}

export default Card;