import { styled } from "../../../stitches.config";

const ImageWrapper = styled('img', {
    objectFit: 'cover',
    '&.navbar-icon': {
        width: '100%',
        maxWidth: '100px',
        height: 'auto',
    },
    variants: {
        type: {
            project: {
                width: '100%',
                borderRadius: '$small',
            },
            displayPhoto: {
                width: '100%',
                borderRadius: '$small',
            },
        },
    },
});

export const Image = ({ 
    src, 
    alt, 
    type,
    className, 
    css,
}) => {
    return (
        <ImageWrapper 
        className={ className }
        src={ src }
        alt={ alt }
        {...type && { type: type }}
        { ...css && {css: { ...css }} }/>
    )
}

export default Image;