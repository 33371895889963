import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCode, faBlog, faSquareEnvelope, faB, } from '@fortawesome/free-solid-svg-icons';

import Row from '../../core/Row';
import Column from '../../core/Column';
import Image from '../../core/Image';
import Button from "../../core/Button";
import Anchor from '../../core/Anchor';
import Container from '../../core/Container';

const navbarStyle = {
    zIndex: '$max',
    background: '$darkViolet',
    boxShadow: '$navbar',
    '> div': {
        maxWidth: '1700px',
        minHeight: '8vh',
    },
    'div.col button': {
        transition: '$default',
    },
}

const links = {
    about: {
        icon: faUser,
    },
    projects: {
        icon: faCode,
    },
    blog: {
        icon: faBlog,
    },
    contact: {
        icon: faSquareEnvelope,
    },
}

const DesktopListGroupView = ({ activeSection, handleNavigator, }) => {
    return (
        <Column className="col p-2 d-flex flex-wrap justify-content-end align-items-center">
        {
            (Object.keys(links).length > 0) && Object.keys(links).map((i, val) => 
                <Button
                key={i}
                text={i.charAt(0).toUpperCase() + i.slice(1).toLowerCase()}
                className={"btn-md navbar-link py-2 px-4 m-2" + ((activeSection && (i !== 'blog') && (activeSection === i)) ? (' ' + 'active') : '')}
                background="none"
                onClick={() => handleNavigator(i)} />
            )
        }
        </Column>
    )
}

const MobileListGroupView = ({ activeSection, handleNavigator, }) => {
    return (
        <Column className="col p-2 d-flex flex-wrap justify-content-end align-items-center">
        {
            (Object.keys(links).length > 0) && Object.keys(links).map((i, val) => 
                <Button
                key={i}
                text={
                    <Container>
                        <FontAwesomeIcon icon={Object.values(links)[val].icon} className="fa-fw fa-lg" />
                    </Container>
                }
                className={"btn-md navbar-link p-2 m-2" + ((activeSection && (i !== 'blog') && (activeSection === i)) ? (' ' + 'active') : '')}
                background="none"
                size="small"
                onClick={() => handleNavigator(i)} />
            )
        }
        </Column>
    )
}

export const Navbar = ({ 
    className,
    handleNavigator,
    active,
    width,
}) => {
    const [activeSection, setActiveSession] = useState('');

    useEffect(() => {
        let loading = true;

        if (loading && active) {
            setActiveSession(active);
        } else if (loading && !(active)) {
            setActiveSession('');
        }

        return () => {
            loading = false;
        }
    }, [active]);

    return (
        <Container 
        className={ className }
        css={{ ...navbarStyle }}>
            <Container className="mx-auto d-flex align-items-stretch">
                <Row className="m-0" css={{ width: '100%', }}>
                    <Column className="col-auto d-flex align-items-center p-2">
                        <Anchor 
                        href="/"
                        text={
                            <Image
                            src="nikkipanda_dev_logo.png"
                            alt="nikkipanda.dev icon"
                            css={{
                                objectFit: 'cover',
                                width: '100%',
                                maxWidth: '70px',
                                height: 'auto',
                                margin: '$1 0px 0px $1',
                            }} />
                        }/>
                    </Column>
                    {
                        (width > 630) ? 
                        <DesktopListGroupView 
                        activeSection={activeSection} 
                        handleNavigator={handleNavigator} /> : 
                        <MobileListGroupView
                        activeSection={activeSection}
                        handleNavigator={handleNavigator} />
                    }
                </Row>
            </Container>
        </Container>
    )
}

export default Navbar;