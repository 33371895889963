import { styled } from "../../../../stitches.config";

const headingStyle = {
    fontFamily: '$plusJakarta',
    transition: 'height ease-in-out .3s',
    color: '$black',
    fontWeight: 700,
}

const Heading1 = styled('h1', 
    headingStyle,
    { 
        fontSize: '4rem',
    }
);
const Heading2 = styled('h2', 
    headingStyle,
    { 
        fontSize: '3.5rem',
    }
);
const Heading3 = styled('h3', 
    headingStyle,
    { 
        fontSize: '3rem',
    }
);
const Heading4 = styled('h4', 
    headingStyle,
    { 
        fontSize: '2.5rem',
    }
);
const Heading5 = styled('h5',
    headingStyle,
    { 
        fontSize: '2rem',
    }
);
const Heading6 = styled('h6',
    headingStyle,
    { 
        fontSize: '1.5rem',
    }
);

const headings = {
    1: Heading1,
    2: Heading2,
    3: Heading3,
    4: Heading4,
    5: Heading5,
    6: Heading6,
}

export const Heading = ({ 
    text, 
    type, 
    className, 
    css,
}) => {
    const Header = headings[type];

    return (
        <Header className={ className } { ...css && { css: { ...css } }}>
            { text }
        </Header>
    )
}

export default Heading;