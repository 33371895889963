import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'aos/dist/aos.css';
import './App.css';
import { storyblokInit, apiPlugin } from "@storyblok/react";
import Project from './components/widgets/Project';

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_KEY,
  use: [apiPlugin],
  components: {
    project: Project,
  },
  apiOptions: {
    // for spaces located in the US or China:
    // region: "us" or "cn", // you need to specify the region
    region: 'us'
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);