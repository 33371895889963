import { createStitches } from '@stitches/react';

export const { 
    styled,
    css,
    globalCss,
    keyframes,
    getCssText,
    theme,
    createTheme,
    config,
 } = createStitches({
    theme: {
        prefix: 'draw',
        colors: {
            white: '#fff',
            black: '#000',
            plasterWhite: '#f9f9f9',
            lightGray: '#F0F0F0',
            gray: '#9c9c9c',
            darkGray: '#858585',
            darkTurquoise: '#00DAE8',
            darkViolet: "#420079",
            violet: '#8700F8',
            pumpkin: '#FF771E',
            orange: '#ffa369',
            pastelTurquoise: '#B0FAFF',
            pastelViolet: '#D6A6FF',
            pastelPumpkin: '#ffe4d3',
            blueGreen: '#00c8d6',
            red: '#ff7477',
        },
        space: {
            1: '5px',
            2: '10px',
            3: '15px',
            4: '20px',
            5: '50px',
        },
        fontSizes: {
            default: '1rem',
            xs: '.8rem',
            small: '.9rem',
            medium: '1.3rem',
            large: '2rem',
        },
        fonts: {
            plusJakarta: 'Plus Jakarta Sans, sans-serif',
            readexPro: 'Readex Pro, sans-serif',
        },
        fontWeights: {},
        lineHeights: {
            default: '200%',
        },
        letterSpacings: {},
        sizes: {},
        borderWidths: {},
        borderStyles: {},
        radii: {
            default: '15px',
            small: '10px',
        },
        shadows: {
            bottom: '0px 40px 40px -35px #9c9c9c',
            navbar: '0px 40px 40px -35px #563670',
            all: '0px 0px 10px -1px #c6c6c6',
        },
        zIndices: {
            max: '999999999',
        },
        transitions: {
            default: 'all .2s ease-in-out',
        },
    },
});

export const globalStyles = globalCss({
    '*': { 
        margin: 0, 
        padding: 0 
    },
    'html': {
        fontSize: '16px',
    },
    'b': {
        color: '$violet',
    },
    'i': {
        color: '$blueGreen',
    },
    'body': {
        background: "top / cover repeat-y scroll url('background_lg_v4.png')",
    },
    'textarea': {
        borderRadius: '$small !important',
    },
    '.ant-form-item-explain-error': {
        color: '$red !important',
    },
    'span.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, span.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover': {
        borderColor: '$red !important',
    },
    'hr': {
        color: '$darkGray ',
    },
    '@import': ["https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&family=Readex+Pro&display=swap"],
});

export const fadeIn = keyframes({
    '0%': { opacity: '0' },
    '100%': { opacity: '1' },
});

export const fadeOut = keyframes({
    '0%': { opacity: '1' },
    '100%': { opacity: '0' },
});

export const slideUp = keyframes({
    '0%': { width: '0px' },
    '100%': { width: '100%' },
});