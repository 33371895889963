import { styled } from "../../../stitches.config";

const AnchorWrapper = styled('a', {
    color: '$pumpkin',
    fontWeight: 'bold',
    '&:hover': {
        textDecoration: 'green wavy underline',
    }
});

export const Anchor = ({ 
    text, 
    href, 
    target, 
    className,
    css,
}) => {
    return (
        <AnchorWrapper 
        href={ href } 
        target={ target ? target : '_target' }
        className={ className }
        {...css && {css: {...css}}}>
            { text }
        </AnchorWrapper>
    )
}

export default Anchor;