import Container from "../../core/Container";
import Badge from "../Badge";

const projectTechStyle = {  
    margin: '$4 0px',
    '> div': {
        margin: '$2 $3 0px 0px',
    },
}

export const ProjectTech = ({ values, }) => {
    return (
        <Container className="d-flex flex-wrap" css={{...projectTechStyle}}>
            {
                Object.keys(values).map((_, val) => 
                    <Badge 
                    key={val} 
                    className="project-tech" 
                    values={ Object.values(values)[val] } />
                )
            }
        </Container>
    )
}

export default ProjectTech;