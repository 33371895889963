import { 
    useRef, 
    useState, 
    useEffect, 
    useLayoutEffect,
} from 'react';
import { styled, globalStyles } from './stitches.config';

import BackToTop from './components/widgets/BackToTop';
import Navbar from './components/widgets/Navbar';
import LandingPage from './pages/landing-page';
import About from './pages/about';
import Projects from './pages/projects';
import Contact from './pages/contact';
import Container from './components/core/Container';

import { useStoryblok, } from "@storyblok/react";

const Main = styled('main', {
    width: '100%',
    minHeight: '100vh',
});

function App() {
    globalStyles();

    let slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

    const story = useStoryblok(slug, { version: "published" });

    const [showBackToTop, setShowBackToTop] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const handleIsShowBackToTop = showBackToTop => setShowBackToTop(showBackToTop);
    const handleWidth = width => setWidth(width);

    const navigator = {
        landingPage: '',
        about: useRef(null),
        projects: useRef(null),
        contact: useRef(null),
    }

    const [section, setSection] = useState('');
    
    const handleNavigator = el => {
        if (el === 'blog') {
            window.location.href = "https://blog.nikkipanda.dev";
            return;
        } 

        navigator[el].current.scrollIntoView();
        setSection(el);
    }

    useEffect(() => {
        let loading = true;

        if (loading) {
            window.addEventListener('scroll', () => {
                for (let i in navigator) {
                    if (i !== 'landingPage') {
                        const height = navigator[i].current.getBoundingClientRect().height;
                        const top = navigator[i].current.getBoundingClientRect().top - 200;
                        ((top + height) < height && (top + height) > 0) && setSection(i);
                    } else {
                        setSection('');
                    }
                }
            });
        }
    
        return () => {
            loading = false;
        }
    }, []); 

    useLayoutEffect(() => {
        const toggleBackToTop = () => {
            (window.scrollY > 500) ? handleIsShowBackToTop(true) : handleIsShowBackToTop(false);
        }

        window.addEventListener('scroll', toggleBackToTop);

        return () => {
            window.removeEventListener('scroll', toggleBackToTop);
        };
    }, []);

    useLayoutEffect(() => {
        const updateViewportWidth = () => {
            handleWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateViewportWidth);

        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, []);

    return (
        <Main className="mx-auto">
            <Navbar 
            width={width}
            className="sticky-top" 
            active={ section }
            handleNavigator={ handleNavigator } />
            <Container 
            className="mx-auto px-3" 
            css={{
                maxWidth: '840px',
                '> section:nth-child(n+2)': {
                    borderColor: '$lightGray',
                    borderWidth: '3px',
                    borderStyle: 'none none dashed',
                }
            }}>
                <LandingPage width={width} />
                <About ref={ navigator.about } width={width} />
                <Projects ref={navigator.projects} width={width} content={story.content ?? {}} />
                <Contact ref={navigator.contact} width={width} />
            </Container>
        {
            <BackToTop isShown={showBackToTop}/>
        }
        </Main>
    );
}

export default App;
