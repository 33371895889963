import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { styled, fadeIn, fadeOut } from "../../../stitches.config";
import Button from "../../core/Button";

const BackToTopWrapper = styled('div', {
    display: 'none',
    position: 'fixed',
    bottom: '$3',
    right: '$3',
    '&.fade-in': {
        animation: `${ fadeIn } ease-in-out .2s forwards`,
    },
    '&.fade-out': {
        animation: `${ fadeOut } ease-in-out .2s forwards`,
    },
});

export const BackToTop = ({ isShown }) => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [contentState, setContentState] = useState('');

    const handleBackToTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        let loading = true;

        if (loading) {
            setIsVisible(isShown);
        }

        return () => {
            loading = false;
        }
    });
    
    useEffect(() => {
        let loading = true;

        if (loading) {
            setTimeout(() => {
                if (!isVisible) {
                    setContentState('none');
                } else {
                    setContentState('block');
                }
            }, 500);
        }

        return () => {
            loading = false;
        }
    }, [isVisible]);

    return (
        <BackToTopWrapper className={"d-" + (contentState) + ' ' + ( isVisible ? 'fade-in' : 'fade-out' ) }>
            <Button 
            background="violet"
            text={<FontAwesomeIcon 
                className="fa-2x" 
                icon={ faChevronUp } 
                style={{ color: '#fff', }}/> } 
            onClick={ () => handleBackToTop() } 
            ref={ ref } />
        </BackToTopWrapper>
    ) 
}

export default BackToTop;