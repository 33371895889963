import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from "../../core/Container";
import Button from "../../core/Button";
import Text from "../../core/Typography/Text";
import { links } from "../../../util";

const footerStyle = {
    width: '100%',
    marginTop: '$3',
    '> a:nth-child(n+2)': {
        margin: '0px 0px 0px $2',
    },
    '@media screen and (max-width: 575px)': {
        '> a:nth-child(n+2)': {
            margin: '$2 0px 0px 0px',
        },
    },
}

export const ProjectFooter = ({ type, }) => {
    return (
        <Container className="d-flex flex-column flex-sm-row justify-content-evenly" css={{...footerStyle}}>
            {
                Object.keys(links[type]).map((key, index) => 
                  <Button
                  key={key}
                  className="flex-grow-1 d-flex justify-content-center"
                  href={links[type][key].href}
                  as="a"
                  text={
                      <>
                          <FontAwesomeIcon icon={links[type][key].icon} className="fa-fw fa-2x" />
                          <Text 
                          type="span" 
                          css={{ 
                              marginLeft: '$1',
                          }}>
                              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
                          </Text>
                      </>
                  }
                  color="darkTurquoise"
                  size="small"
                  target="_blank" />
                )
            }
        </Container>
    )
}

export default ProjectFooter;