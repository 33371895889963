import React from "react";
import { styled } from "../../../stitches.config";

const ButtonWrapper = styled('button', {
    fontFamily: '$readexPro',
    border: 'unset',
    background: '$lightGray',
    borderRadius: '$default',
    padding: '$2 $4',
    transition: 'all .2s',
    fontWeight: 'bold',
    color: '$black',
    variants: {
        background: {
            transparent: {
                background: 'none',
                borderRadius: 'unset',
            },
            darkTurquoise: {
                color: '$white',
                background: '$darkTurquoise',
            },
            pumpkin: {
                color: '$white',
                background: '$pumpkin',
            },
            violet: {
                color: '$white',
                background: '$violet',
            },
        },
        color: {
            blueGreen: {
                color: '$blueGreen',
            },
            pumpkin: {
                color: '$pumpkin',
            },
        },
        size: {
            small: {
                fontSize: '$small',
            },
            medium: {
                fontSize: '$medium',
            },
            large: {
                fontSize: '$large',
            },
        },
    },
    '&.navbar-link': {
        textTransform: 'uppercase',
        color: '$white',
        boxShadow: 'none',
        background: 'unset',
        '&:hover': {
            color: '$pastelViolet',
        },
    },
    '&:hover': {
        color: '$violet',
    },
    '&.active': {
        color: '$orange',
    },
});

export const Button = React.forwardRef(({
    text,
    className,
    background,
    size,
    as,
    type,
    color,
    href,
    target,
    onClick,
}, ref) => {
    return (
        <ButtonWrapper
        className={ className }
        { ...href && { href: href }}
        { ...type && { type: type }}
        { ...ref && { ref: ref }}
        { ...as && { as: as }}
        { ...target && { target: target }}
        { ...background && { background: background }}
        {...color && { color: color }}
        {...size && { size: size }}
        {...onClick && { onClick: evt => onClick(evt) }}>
            { text }
        </ButtonWrapper>
    )
});

export default Button;