import { styled } from "../../../../stitches.config";

const textStyle = {
    lineHeight: '$default',
    fontFamily: '$readexPro',
    fontSize: '$default',
    variants: {
        color: {
            secondary: {
                color: '$darkGray',
            }
        },
        style: {
            callout: {
                border: '1px solid $gray',
            }
        },
        size: {
            tiny: {
                fontSize: '$xs',
            },
        },
    },
}

const ParagraphWrapper = styled('p', 
    textStyle,
    {
        textAlign: 'justify',
    }
);

const SpanWrapper = styled('span', 
    textStyle,
    {}
);

const textType = {
    paragraph: ParagraphWrapper,
    span: SpanWrapper,
}

export const Text = ({ 
    children, 
    type, 
    as, 
    style,
    size,
    className,
    color,
    css,
}) => {
    const TextEl = textType[type];

    return (
        <TextEl 
        className={ className }
        type={ type } 
        {...size && { size: size }}
        { ...color && { color: color }}
        { ...style && { style: style }}
        { ...as && {as: as } }
        { ...css && { css: { ...css } }}>
            { children }
        </TextEl>
    )
}

export default Text;