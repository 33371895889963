import { faGithub, } from "@fortawesome/free-brands-svg-icons";

export const func = (props) => {
    Object.keys(props.fields).map((i, _) => {
        if (i === props.field_name) {
            props.fields[i].setValidateStatus('error');
            props.fields[i].setHelp(props.message);
        } else {
            props.fields[i].setValidateStatus('success');
        }
    })
}

export const links = {
    giftery: {
        links: {},
    },
    student_info: {
        server: {
            href: "https://github.com/nikkipanda-dev/student-info-system-server",
            icon: faGithub,
        },
        admin: {
            href: "https://github.com/nikkipanda-dev/student-info-system-admin",
            icon: faGithub,
        },
        student: {
            href: "https://github.com/nikkipanda-dev/student-info-system",
            icon: faGithub,
        },
    },
}