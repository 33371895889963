import Container from "../../core/Container";
import Text from "../../core/Typography/Text";

const badgeStyle = {
    borderRadius: '$small',
    padding: '$2',
    '&.project-tech': {
        background: '$pastelPumpkin',
        color: '$pumpkin',
    }
}

export const Badge = ({ 
    values,
    className,
}) => {
    return (
        <Container 
        className={"d-flex justify-content-center" + (className ? (' ' + className) : '')} 
        css={{...badgeStyle}}>
            <Text type="span" size="tiny">{values}</Text>
        </Container>
    )
}

export default Badge;