import Container from "../../core/Container";
import Heading from "../../core/Typography/Heading";
import Text from "../../core/Typography/Text";
import Image from "../../core/Image";
import AboutListGroup from "../AboutListGroup";

export const About = () => {
    return (
        <>
            <Heading type={2} text="About" />
            <Text type="paragraph">
                It all started when I was fiddling around with Blogger templates around a decade ago. My journey began with Blogskins, a popular blogging tool that allowed users to make their custom designs. What started as a little hobby project has turned into a passion. I did not know much about coding back then—just CSS, HTML, and a bit of JavaScript. I got sidetracked and went down a different path for a bit. Years later, I decided to pursue web development further to get serious about my craft.
            </Text>
            <Container className="d-flex flex-column flex-md-row" css={{ marginTop: '30px', }}>
                <Container
                    className="mx-auto pe-0 pe-md-3"
                    css={{ 
                        width: '300px', 
                        height: '300px',
                    }}>
                    <Image src="https://nikkipanda.sfo3.digitaloceanspaces.com/display_photos/dp_1.png" type="displayPhoto" />
                </Container>
                <Container
                    className="flex-sm-grow-1 ps-0 ps-md-3"
                    css={{
                        padding: '10px 0px',
                        'a': {
                            color: '$black',
                        },
                    }}>
                    <AboutListGroup />
                </Container>
            </Container>
        </>
    )
}

export default About;