import { styled } from "../../../stitches.config";

const ColumnWrapper = styled('div', {});

export const Column = ({ 
    children, 
    className,
    css,
}) => {
    return (
        <ColumnWrapper className={ className } {...css && {css: {...css}}}>
            { children }
        </ColumnWrapper>
    )
}

export default Column;