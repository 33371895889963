import React from "react";
import { styled } from "../../../stitches.config";

const SectionWrapper = styled('section', {
    transition: '$default',
    backgroundColor: 'rgba(255, 255, 255, .15)',
    backdropFilter: 'blur(10px)',
});

export const Section = React.forwardRef(({ 
    children, 
    className, 
    css,
}, ref) => {
    return (
        <SectionWrapper
        className={ className }
        { ...ref && {ref: ref} }
        { ...css && { css: { ...css } }}>
            { children }
        </SectionWrapper>
    )
});

export default Section;