import axios from "axios";
import { Directus, } from '@directus/sdk';

// axios.defaults.withCredentials = true;

export const axiosDefault = axios.create({
    headers: { 
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
    }
});

export const directus = new Directus('https://cj23ry6h.directus.app', {
    transport: {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/JSON',
        }
    },
});

export default axiosDefault;