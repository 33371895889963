import React from 'react';
import AOS from 'aos';

import Section from '../../components/core/Section';
import Row from '../../components/core/Row';
import Column from '../../components/core/Column';
import Container from '../../components/core/Container';
import Heading from '../../components/core/Typography/Heading';
import Project from '../../components/widgets/Project';

import { StoryblokComponent, storyblokEditable, } from "@storyblok/react";

AOS.init();

const projectStyle = {
    padding: '$2',
    '> div:nth-child(n+2)': {
        marginTop: '$5',  
    },
}

export const Projects = React.forwardRef(( { width, content }, ref ) => {  
    return (
        width && 
        <Section 
        css={{ minHeight: '90vh', }} 
        ref={ ref }>
            <Container css={{
                paddingTop: '100px',
            }}
            dataAos="fade-up"
            dataAosDelay={200}
            dataAosDuration={300}
            dataAosEasing="ease-in-out"
            dataAosMirror={true}
            dataAosOnce={true}
            dataAosAnchorPlacement="top-center">
                <Row className="mx-0">
                    <Column 
                    className="col-12"
                    css={{...projectStyle}}>
                        <Heading type={2} text="Projects" />
                    {
                        content && content.body &&
                        content.body.map((blok) => {
                            return (
                                <Project key={blok._uid} blok={blok} />
                            )
                        }) 
                    }
                    </Column>
                </Row>
            </Container>
        </Section>
    )
});

export default Projects;